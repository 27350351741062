import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useMemo } from "react";
import Button from "../../components/Button";
import { SkewMainContainer } from "../../components/Container";
import { SectionTitle } from "../../components/Title";
import {
  productEnterPrise1,
  productEnterPrise2,
  productEnterPrise3,
} from "../../images/product";
import { ListProps } from "../product";

type EnterpriseCustom = ListProps & { logoHeight: number };
const EnterpriseCustomContent = () => {
  const { t } = useTranslation();
  const enterpriseCustom: EnterpriseCustom[] = useMemo(
    () => [
      {
        logo: productEnterPrise1,
        logoHeight: 170,
        title: t("企业级部署"),
        content: t(
          "支持企业级部署，包括云端部署和私有化部署，您都将获得最适合、最安全的服务"
        ),
      },
      {
        logo: productEnterPrise2,
        logoHeight: 170,
        title: t("专属项目经理"),
        content: t(
          "我们为每个客户提供技术、开发和集成支持，并有专属的项目经理全程陪伴，软件部署的全流程都由星尘来帮您分担。"
        ),
      },
      {
        logo: productEnterPrise3,
        logoHeight: 170,
        title: t("系统集成与定制化"),
        content: t(
          "将系统集成到您的AI开发工作流中，通过API暴露出系统的各种状态；支持数据结构化适配，满足您的数据安全流程。"
        ),
      },
    ],
    [t]
  );
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
      {enterpriseCustom.map((list) => {
        return (
          <div
            key={list.title}
            className="flex flex-col items-center justify-between"
          >
            <div className="mb-12">
              <h4 className="mb-2 text-size-24 w-full">{list.title}</h4>
              <p className="text-color-blue-gary text-size-14">
                {list.content}
              </p>
            </div>
            <img
              style={{ height: list.logoHeight }}
              src={list.logo as string}
              alt=""
            />
          </div>
        );
      })}
    </div>
  );
};

const EnterpriseCustomView = () => {
  const { t } = useTranslation();
  return (
    <SkewMainContainer fillClassName="bg-white" className="pt-8 pb-12">
      <SectionTitle id="enterpriseCustom" className="mb-8">
        {t("企业定制")}
      </SectionTitle>
      <EnterpriseCustomContent />
      <div className="flex justify-center mt-12">
        <Button className="w-[200px]" onClick={() => navigate("/contact")}>
          {t("我要咨询企业定制")}
        </Button>
      </div>
    </SkewMainContainer>
  );
};
export default EnterpriseCustomView;
