import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { FC } from "react";
import Tilt from "react-tilt";
import { ProductPageQuery } from "../../graphql-types";
import Button from "../components/Button";
import { MainContainer, PageContainer } from "../components/Container";
import Footer from "../components/Footer";
import Header, { ThemeEnum } from "../components/Header";
import Metadata from "../components/Metadata";
import SearchInput from "../components/SearchInput";
import SkewGradientBg from "../components/SkewGradientBg";
import { PageDescribe, PageTitle } from "../components/Title";
import {
  productCover,
  ProductIcon,
  product_cover_img_01,
  product_cover_img_02,
  product_cover_img_03,
  product_tools,
} from "../images/product";
import { usePageMetadata } from "../metadata/page";
import LearnMoreView from "../Views/LearnMoreView";
import EnterpriseCustomView from "../Views/productViews/CompanyView";
import CompleteDataToolsView from "../Views/productViews/CompleteDataToolsView";
import DataSecurityView from "../Views/productViews/DataSecurityView";
import HaveCompleteAPIView from "../Views/productViews/HaveCompleteAPIView";
import ProductHighlights from "../Views/productViews/ProductHighlights";
import ProvideTechnicalSolutionsView from "../Views/productViews/ProvideTechnicalSolutionsView";
import StandaloneAnnotationToolView from "../Views/productViews/StandaloneAnnotationToolView";
import { PageTitleWithIcon } from "./price";

const ProductPage: FC<{ data: ProductPageQuery }> = ({
  data: { allContentfulDataType, allContentfulSolutions },
}) => {
  const { t } = useTranslation();
  const { product } = usePageMetadata();
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.dark} />
      <Metadata {...product} />
      <SkewGradientBg
        id="gradient-product"
        fillClassName="light-white-bg"
        gradientHeight={1048}
        className="sm:h-[1048px]"
      />
      <MainContainer className="mb-20">
        <PageTitleWithIcon title={t("产品")} icon={<ProductIcon />} />
        <div className="flex flex-wrap relative">
          <div className="w-full sm:w-[460px]">
            <PageTitle>{t("强大的标注工具触手可及")}</PageTitle>
            <PageDescribe className="mt-6">
              {t(
                "星尘标注平台集标注工具、AI辅助、质量管理为一体，自主开发，确保数据安全。"
              )}
            </PageDescribe>
          </div>
          {/* 产品cover */}
          <div className="relative sm:ml-6 mt-8 sm:mt-0 sm:scale-75 sm:w-auto w-full">
            <img className="sm:hidden w-full" src={productCover} alt="" />
            <div className="sm:absolute z-30 sm:-mt-20">
              <Tilt
                className="Tilt max-w-none w-full sm:w-[800px] h-96 hidden sm:block"
                options={{
                  max: 15,
                  reverse: true,
                  speed: 1500,
                  perspective: 2000,
                }}
              >
                <img
                  src={product_cover_img_01}
                  alt=""
                  className="absolute z-30"
                  style={{ left: "402px", top: "284px", width: "352px" }}
                />
                <img
                  src={product_cover_img_02}
                  alt=""
                  className="absolute z-20"
                  style={{ left: "0px", top: "284px", width: "400px" }}
                />
                <img
                  src={product_cover_img_03}
                  alt=""
                  className="absolute z-10"
                  style={{ left: "75px", top: "-20px", width: "487px" }}
                />
                <div className="Tilt-inner">
                  <img
                    src={product_tools}
                    className="absolute"
                    alt=""
                    style={{ left: "0", top: "0", width: "800px" }}
                  />
                </div>
              </Tilt>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-[378px] mt-10">
          <SearchInput />
          <div className="flex justify-between mt-7">
            <Button onClick={() => navigate("/contact")}>
              {t("联系销售")}
            </Button>
          </div>
        </div>
      </MainContainer>
      <StandaloneAnnotationToolView />
      <ProvideTechnicalSolutionsView
        technologyType={allContentfulDataType.nodes ?? []}
        technicalSolutions={allContentfulSolutions.nodes ?? []}
      />
      <CompleteDataToolsView />
      <HaveCompleteAPIView />
      <DataSecurityView />
      <EnterpriseCustomView />
      <ProductHighlights />
      <LearnMoreView
        leftUrl={"/contact"}
        leftText={t("填写需求表格")}
        rightText={t("了解价格")}
        rightUrl={"/price"}
      />
      <Footer />
    </PageContainer>
  );
};

export default ProductPage;

export const query = graphql`
  query ProductPage($language: String!) {
    ...AllLangauages
    allContentfulDataType(filter: { node_locale: { eq: $language } }) {
      nodes {
        slug
        title
      }
    }
    allContentfulSolutions(filter: { node_locale: { eq: $language } }) {
      nodes {
        coverImage {
          ...ContentfulImage200x200
        }
        name
        slug
        technologyType {
          title
          slug
        }
        description {
          description
        }
      }
    }
  }
`;
