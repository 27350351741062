import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useMemo, useRef, useState } from "react";
import { Swiper as SwiperClass } from "swiper/types";
import { ProductPageQuery } from "../../../graphql-types";
import CardSwiper from "../../components/CardSwiper";
import { MainContainer, SkewContainer } from "../../components/Container";
import ContentfulImage from "../../components/ContentfulImage";
import { TabBar } from "../../components/Tabs";
import { SectionTitle } from "../../components/Title";

type TypeItem = ProductPageQuery["allContentfulDataType"]["edges"][number];
type SolutionsItem =
  ProductPageQuery["allContentfulSolutions"]["edges"][number];

const ProvideTechnicalSolutionsView = ({
  technicalSolutions,
  technologyType,
}: {
  technologyType: TypeItem[];
  technicalSolutions: SolutionsItem[];
}) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const showTabs = [{ title: t("全部"), slug: "all" }, ...technologyType];
  const imgRef = useRef<CarouselRef>(null);
  let cardRef = useRef<SwiperClass>(null);
  const showItems = useMemo(() => {
    const { slug } = showTabs[activeIndex];
    return slug === "all"
      ? technicalSolutions
      : technicalSolutions.filter((t) => t.technologyType?.slug === slug);
  }, [technicalSolutions, activeIndex]);
  const hoverProps = {
    onMouseLeave: () => {
      cardRef.current?.autoplay.start();
    },
    onMouseEnter: () => {
      cardRef.current?.autoplay.stop();
    },
  };
  return (
    <SkewContainer fillClassName="dark-blue">
      <MainContainer>
        <SectionTitle id="technicalSolutions" className="text-white">
          {t("我们提供的技术方案")}
        </SectionTitle>
        <TabBar
          className="mt-12 mb-6"
          type="light"
          tabs={showTabs.map((t) => t.title)}
          activeIndex={activeIndex}
          onSelectIndex={(index) => setActiveIndex(index)}
        />
        <Carousel dots={false} ref={imgRef} autoplay={false} touchMove={false}>
          {showItems.map(({ coverImage, description, name }, index) => (
            <div key={index} {...hoverProps}>
              <ContentfulImage className="w-full" image={coverImage!} />
              <p className="text-white mt-6 text-center">
                {name}：
                <span className="opacity-60">{description.description}</span>
              </p>
            </div>
          ))}
        </Carousel>
      </MainContainer>

      <CardSwiper
        initRef={(ref) => {
          // @ts-ignore
          cardRef.current = ref;
        }}
        slideProps={hoverProps}
        onAutoplay={() => imgRef.current?.next()}
        onActiveIndexChange={(activeIndex) => {
          imgRef.current?.goTo(activeIndex);
        }}
        onNext={() => {
          imgRef.current?.next();
        }}
        onPrev={() => {
          imgRef.current?.prev();
        }}
      >
        {showItems.map((item, index) => (
          <span key={index}>{item.name}</span>
        ))}
      </CardSwiper>
    </SkewContainer>
  );
};

export default ProvideTechnicalSolutionsView;
