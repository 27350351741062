import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import Button from "../../components/Button";
import { SkewMainContainer } from "../../components/Container";
import { HaveCompleteAPICode } from "../../components/Termynal/Index";
import { SectionTitle } from "../../components/Title";

const HaveCompleteAPIView = () => {
  const { t } = useTranslation();
  return (
    <SkewMainContainer fillClassName="dark-blue">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-16">
        <div>
          <SectionTitle id="completeAPI" className="mb-8 text-white">
            {t("具备完善的API")} <br />
            {t("可供企业快速接入")}
          </SectionTitle>
          <p className="text-color-light-blue mb-8">
            {t(
              "支持自定义API接入，在Stardust标注平台开发了多个API接入入口，一键操作，直接配置对应的URL，无论是语音、图像、视频、文本的标注，均可让您更快接入。"
            )}
          </p>
          <Button
            className="mt-16 sm:mt-16"
            onClick={() => navigate("https://docs.stardust.ai")}
          >
            {t("查看接入文档")}
          </Button>
        </div>
        <HaveCompleteAPICode />
      </div>
    </SkewMainContainer>
  );
};

export default HaveCompleteAPIView;
