import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import Tilt from "react-tilt";
import { SkewMainContainer } from "../../components/Container";
import { SectionTitle } from "../../components/Title";
import { completeDataTool } from "../../images/index/index";
import {
  PngProductManageBack,
  PngProductManageFront,
} from "../../images/product";

const CompleteDataToolsView = () => {
  const { t } = useTranslation();
  return (
    <SkewMainContainer fillClassName="light-white-bg">
      <SectionTitle id="completeDataTool" className="mb-16">
        {t("完善的数据采集与项目管理工具")}
      </SectionTitle>
      <div className="sm:scale-90 sm:h-[602px]">
        <img className="sm:hidden" src={completeDataTool} alt="" />
        <Tilt
          className="Tilt max-w-none w-full sm:w-[1180px] h-[602px] absolute hidden sm:block"
          style={{ left: "-100px", top: "0" }}
          options={{
            max: 15,
            reverse: true,
            speed: 1500,
            perspective: 1500,
          }}
        >
          <img
            src={PngProductManageBack}
            alt=""
            className="absolute"
            style={{ left: "169px", top: "0", width: "852px" }}
          />
          <div className="Tilt-inner">
            <img
              src={PngProductManageFront}
              className="absolute"
              alt=""
              style={{ left: "0", top: "111px", width: "1180px" }}
            />
          </div>
        </Tilt>
      </div>
    </SkewMainContainer>
  );
};

export default CompleteDataToolsView;
