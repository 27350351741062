import classNames from "classnames";
import * as React from "react";
import { memo, ReactNode, useRef, useState } from "react";
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { Swiper, SwiperSlide } from "swiper/react";
import { debounce, useMediaScreen } from "../utils/utils";

SwiperCore.use([EffectCoverflow, Autoplay]);

const CardSwiper = memo(
  (props: {
    onAutoplay?: () => void;
    children: ReactNode[];
    slideProps: { onMouseLeave: () => void; onMouseEnter: () => void };
    initRef: (ref: SwiperCore) => void;
    cardClassName?: string;
    onActiveIndexChange?: (activeIndex: number) => void;
    onNext?: () => void;
    onPrev?: () => void;
  }) => {
    const swiperRef = useRef<SwiperCore>();
    const [prevIndex, setPrevIndex] = useState(0);
    const { isSmScreen } = useMediaScreen();
    const clickSlide = (index: number) => {
      swiperRef.current?.slideToLoop(index);
      props.onActiveIndexChange?.(index);
    };
    const debounceClickSlide = debounce(clickSlide, 200);
    return (
      <div className="max-w-[1200px] mx-auto relative card-swiper-wrap z-10">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 26,
            stretch: 0,
            depth: 100,
            modifier: 1,
          }}
          autoplay={{ delay: 2000 }}
          className="mt-12"
          loop
          onAutoplay={(swiper) => {
            if (!isSmScreen) {
              return;
            }
            props.onAutoplay?.();
            setPrevIndex(swiper.activeIndex);
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            props.initRef(swiper);
            setPrevIndex(swiper.activeIndex);
          }}
          allowTouchMove={!isSmScreen}
          onActiveIndexChange={(swiper) => {
            const { activeIndex } = swiper;
            if (isSmScreen) {
              return;
            }
            if (prevIndex === activeIndex || prevIndex === 0) {
              return;
            }
            if (prevIndex > activeIndex) {
              props.onPrev?.();
            } else {
              props.onNext?.();
            }
            setPrevIndex(activeIndex);
          }}
        >
          {props.children.map((child, index) => (
            <SwiperSlide
              onClick={() => debounceClickSlide(index)}
              key={index}
              {...props.slideProps}
            >
              <div
                key={index}
                className={classNames(
                  "swiper-slide-item text-center text-white flex justify-center items-center",
                  props.cardClassName
                )}
              >
                {child}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
);

export default CardSwiper;
