import type { PublicAnnotationInput } from "@startask/brigitte";
import { TorbjornIframe } from "@startask/brigitte";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { SkewMainContainer } from "../../components/Container";
import { TabBar } from "../../components/Tabs";
import { SectionTitle } from "../../components/Title";
import {
  audio,
  image_cubeobjectdetect,
  image_facial,
  image_laneline,
  image_objectdetect,
  image_ocr,
  image_segmentation,
  points_mapping,
  points_objectdetect,
  points_segmentation,
  text_objectdetect,
  video_objecttrack,
  video_road,
} from "../../images/product";
import { useMediaScreen } from "../../utils/utils";

const TorbjornLicense =
  "eyJ0b29sT3JpZ2luIjoiKiIsImhvc3RPcmlnaW4iOiIqIiwiZXhwaXJhdGlvbiI6MjUyODE5NjMxNjMwNywicGF0aHMiOlsiYW5ub3RhdGlvblRvb2wiLCJwb2ludENsb3VkQWRhcHRlciIsImFubm90YXRlIl0sImZlYXR1cmVGbGFncyI6eyJoaWRlQWxsQ2hyb21lIjp0cnVlfX0=.krubPX/IMx9maAoDsdAgjKVo80mZUP7uHBwuaPu/0htvjB8KknKEuvhhzeP7tqPmvpN5YdUgJbkNyrLU1HDxqGBgfsRWSo1pXR59BhtvnteBsgitJtH30jn6n198Foewa9pfwN8rXBSkrHDe6VipZkIQ1WZbIzVTozfDwJjquLwzWN5cVW3Qw0Q78KCaimqAutwttfqpz2LQxmSJkKIeodebrybarQrUzX9dLmE1ouYm1BIVvJ4Dj6EeCT0K+/aBAYJSDjxzLRUY3+OyC1yybodBafeQh7v/E6PSNJslo0JnD/hoeq7SVApcG+Nz/csZUBfyY1AYnQHYsCwgV3APTerRRHZQOhzSE9gDsNyVIVmCrEN0xxyTaz3gdQgiByvcQXctN/DR4wqsYC5dmbEJJEHEeDx0nMo+M+yu5OR/0RPWIEaVaptl8/hConke/Ma++n7h8i6FisHxC7gwPi+RbXVUes324s23yMxhvSSc7k/6Ayf8/KzjcBAfDbNruXvT8LgaO45U0suc/uey4cSJqQQKV4W2Rh1WX5egpJQe3c/Ov/3Lk7gIP2oYkH4HZS5sDGUCzcc9UEq0hF16B9unLD6v0tqJQgF00WFUYqyE5waS7fw2L5MJ2354BC6TzynlqHjVwViuD0VaSGnFyj+9/9CNtSsJXfXfWgvbpoUvxq8=";

const ToolContent = () => {
  const [activeIndex, setActiveIndex] = useState([0, 0]);
  const { t } = useTranslation();
  const { isSmScreen } = useMediaScreen();
  const list: {
    title: string;
    payload: {
      title: string;
      payload: () => Promise<{ default: any }>;
      pref?: Record<string, any>;
      imgUrl?: string;
    }[];
  }[] = useMemo(
    () => [
      {
        title: t("点云"),
        payload: [
          {
            title: t("点云物体识别"),
            payload: () => import("./assets/点云-点云物体识别.js"),
            imgUrl: points_mapping,
          },
          {
            title: t("点云映射"),
            payload: () => import("./assets/点云-点云映射.js"),
            imgUrl: points_objectdetect,
          },
          {
            title: t("点云语义分割"),
            payload: () => import("./assets/点云-语义分割.js"),
            pref: { instanceLabelShow: "DoNotShow", segmentationMode: true },
            imgUrl: points_segmentation,
          },
        ],
      },
      {
        title: t("图像"),
        payload: [
          {
            title: t("对象检测"),
            payload: () => import("./assets/图片-对象检测.js"),
            pref: {
              instanceLabelShow: "DetailModel",
            },
            imgUrl: image_cubeobjectdetect,
          },
          {
            title: t("人脸识别"),
            payload: () => import("./assets/图片-人脸识别.js"),
            pref: { instanceLabelShow: "DoNotShow" },
            imgUrl: image_facial,
          },
          {
            title: t("立体对象检测"),
            payload: () => import("./assets/图片-立体对象检测.js"),
            pref: { instanceBgPercentage: 60 },
            imgUrl: image_objectdetect,
          },
          {
            title: t("语义分割"),
            payload: () => import("./assets/图片-语义分割.js"),
            pref: { instanceLabelShow: "DoNotShow", instanceBgPercentage: 80 },
            imgUrl: image_segmentation,
          },
          {
            title: t("车道线识别"),
            payload: () => import("./assets/图片-车道线识别.js"),
            imgUrl: image_laneline,
          },
          {
            title: t("OCR"),
            payload: () => import("./assets/图片-OCR.js"),
            imgUrl: image_ocr,
            pref: {
              showPointIndex: true,
              isShowBoundaryPoint: true,
              randomInstanceColor: true,
              instanceLabelShow: "DoNotShow",
            },
          },
        ],
      },
      {
        title: t("文本"),
        payload: [
          {
            title: t("实体识别"),
            payload: () => import("./assets/文本-实体识别.js"),
            imgUrl: text_objectdetect,
          },
        ],
      },
      {
        title: t("视频"),
        payload: [
          {
            title: t("目标追踪"),
            payload: () => import("./assets/视频-目标追踪.js"),
            pref: { instanceLabelShow: "DoNotShow" },
            imgUrl: video_objecttrack,
          },
          {
            title: t("车道线识别"),
            payload: () => import("./assets/视频-车道线识别.js"),
            pref: { instanceLabelShow: "DoNotShow" },
            imgUrl: video_road,
          },
        ],
      },
      {
        title: t("语音"),
        payload: [
          {
            title: t(""),
            payload: () => import("./assets/官网-语音标注.json"),
            imgUrl: audio,
          },
        ],
      },
    ],
    [t]
  );
  const tabs = useMemo(() => list.map((l) => l.title), [list]);
  const subTabs = useMemo(
    () => list[activeIndex[0]].payload.map((l) => l.title),
    [list, activeIndex[0]]
  );
  const [task, setTask] = useState<PublicAnnotationInput>();
  const [prefs, setPrefs] = useState<Record<string, any>>();
  const [indexA, indexB] = activeIndex;
  const [currentImgUrl, setCurrentImgUrl] = useState<string>();
  useEffect(() => {
    const option = list[indexA].payload[indexB];
    setCurrentImgUrl(option.imgUrl);
    option.payload().then((r) => {
      setPrefs({
        pointCloudShowIntensity: false,
        segmentationMode: false,
        instanceLabelShow: "ShowAll",
        instanceBgPercentage: 15,
        showPointIndex: false,
        connectingPointsIntoLine: false,
        AnnotationActiveSlotPropertyFormStore: {
          model: "FLOAT",
          pin: false,
          position: {
            left: 0,
            top: 0,
          },
        },
        ...option.pref,
      });
      setTask(r.default);
    });
  }, [indexA, indexB]);
  return (
    <>
      <TabBar
        tabs={tabs}
        activeIndex={activeIndex[0]}
        onSelectIndex={(index) => setActiveIndex([index, 0])}
      />
      <div className="rounded-md mt-6 overflow-hidden bg-black sm:w-[1140px] sm:ml-[-70px]">
        <TabBar
          tabs={subTabs}
          activeIndex={activeIndex[1]}
          onSelectIndex={(index) => setActiveIndex((p) => [p[0], index])}
          type={"text"}
        />
        {typeof window !== "undefined" &&
          (isSmScreen ? (
            <TorbjornIframe
              preferences={prefs}
              className="h-[640px] w-full"
              host="https://work.startask.net/iframe?publicKey=/signedPublicKeys/publicKey.json.sig&lng=en-US"
              // host="https://torbjorn.startask.net/iframe?publicKey=/signedPublicKeys/publicKey.json.sig"
              // host="https://localhost:3000/iframe?publicKey=/signedPublicKeys/publicKey.json.sig&lng=en-US"
              // host="https://standalone.startask.net/?publicKey=/signedPublicKeys/publicKey.json.sig"
              task={task!}
              license={TorbjornLicense}
            />
          ) : (
            <img src={currentImgUrl} alt="" />
          ))}
      </div>
    </>
  );
};

const StandaloneAnnotationToolView = () => {
  const { t } = useTranslation();
  return (
    <SkewMainContainer fillClassName="light-white-bg" className="z-20">
      <SectionTitle id="computerVision" className="mb-16">
        {t("自主研发的高效标注工具")}
      </SectionTitle>
      {/* <SectionDescribe className="mb-16">
        {t(
          "星尘自主研发的标注平台，覆盖全领域，系国内唯一。"
        )}
      </SectionDescribe> */}
      <ToolContent />
      <div className="block sm:hidden text-base text-center w-full mt-4">
        {t("* 请用桌面端浏览器访问，体验可操作版本")}
      </div>
    </SkewMainContainer>
  );
};

export default StandaloneAnnotationToolView;
