import classNames from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { memo } from "react";
import { SkewMainContainer } from "../../components/Container";
import FlipCard from "../../components/FlipCard /FlipCard";
import { SectionDescribe, SectionTitle } from "../../components/Title";
import {
  group_01,
  group_02,
  group_03,
  group_04,
  group_05,
  group_06,
  highlight_01,
  highlight_02,
  highlight_03,
  highlight_04,
  highlight_05,
  highlight_06,
  highlight_07,
  highlight_10,
  highlight_11,
  highlight_12,
  highlight_13,
  highlight_14,
  highlight_15,
  highlight_16,
  highlight_17,
  highlight_18,
  highlight_19,
  highlight_20,
  highlight_21,
  highlight_22,
  highlight_23,
  highlight_24,
  highlight_25,
  highlight_26,
  highlight_27,
  highlight_28,
} from "../../images/product";

type GroupCardProps = {
  title: string;
  rightIcon: string;
  list: string[];
};

type HighlightCardProps = {
  title: string;
  icon: string;
  backText: string;
};

const ProductHighlights = () => {
  const { t } = useTranslation();
  const lists: HighlightCardProps[] = [
    {
      title: t("标注通用"),
      icon: highlight_06,
      backText: t(
        "支持项目灵活配置、模板导入、父子任务、AI算法辅助等配置能力。"
      ),
    },
    {
      title: t("标注界面"),
      icon: highlight_07,
      backText: t(
        "标注工具经过长期打磨，具有30多项功能，支持各种标注流程。例如：标尺、辅助线、高亮、筛选、边界、附加信息、挂起、消息等。"
      ),
    },
    {
      title: t("点"),
      icon: highlight_01,
      backText: t("点标注工具，支持序号、点组、颜色等工作。"),
    },
    {
      title: t("结果面板"),
      icon: highlight_02,
      backText: t(
        "简洁的结果显示方式，让标注流程和检验流程非常高效。包括高亮、隐藏、锁定、预览、属性弹出等功能。"
      ),
    },
    {
      title: t("语音/音频"),
      icon: highlight_03,
      backText: t("语音标注支持音频段分割、分类、转译等功能。"),
    },
    {
      title: t("选择"),
      icon: highlight_04,
      backText: t("选择属性工具，支持默认选项、多选、搜索、API接入等功能。"),
    },
    {
      title: t("2D框"),
      icon: highlight_11,
      backText: t(
        "2D Box工具，支持大小数量限制、旋转、形状调整、预设大小等功能。"
      ),
    },
    {
      title: t("多边形"),
      icon: highlight_10,
      backText: t(
        "多边形标注工具，支持分割、调整形状、增删点、笔刷、橡皮擦等功能。"
      ),
    },
    {
      title: t("立方体"),
      icon: highlight_05,
      backText: t("立方体标注工具，支持前后面、灭点、旋转等功能。"),
    },
    {
      title: t("项目管理"),
      icon: highlight_28,
      backText: t(
        "项目管理系统，支持进度查看、人员管理、角色管理、错误统计、团队记录、操作项配置。"
      ),
    },
    {
      title: t("文本输入"),
      icon: highlight_27,
      backText: t("文本输入工具，支持API接入、知识图谱、数据校验等功能。"),
    },
    {
      title: t("3D标注"),
      icon: highlight_26,
      backText: t(
        "支持3D标注的各种场景，例如3D Box、3D语义分割、融合标注、连续帧标注。"
      ),
    },
    {
      title: t("数据采集工具"),
      icon: highlight_25,
      backText: t("数据采集、收集工具，支持表单、语音、文本、图像等采集方式。"),
    },
    {
      title: t("质量控制"),
      icon: highlight_24,
      backText: t(
        "质量控制系统，支持多重指标定义、标注对象级别的质检和错误记录，能以多重方式进行抽检和统计、回溯历史记录，确保标注对象级别的数据质量。"
      ),
    },
    {
      title: t("NLP"),
      icon: highlight_23,
      backText: t("NLP标注工具，支持实体标注和实体关系等功能。"),
    },
    {
      title: t("用户管理"),
      icon: highlight_22,
      backText: t(
        "用户管理系统，支持角色配置、用户权限、人员管理、历史查看等功能。"
      ),
    },
    {
      title: t("工作统计"),
      icon: highlight_21,
      backText: t(
        "工作统计系统，能够按多种层级、对象、视角统计，支持20余种统计值，并可以自定义统计项和统计界面。"
      ),
    },
    {
      title: t("算法辅助标注"),
      icon: highlight_20,
      backText: t(
        "辅助标注算法，包括3D、CV、NLP、ASR等标注工具，支持10余种场景，极大提高标注效率。"
      ),
    },
    {
      title: t("算法辅助质检"),
      icon: highlight_19,
      backText: t("算法辅助质检模块，支持多种质检场景，可自定义API接入。"),
    },
    {
      title: t("算法接入"),
      icon: highlight_18,
      backText: t(
        "算法接入管理系统，可以接入算法、脚本、API，可以管理大型任务的运行时。"
      ),
    },
    {
      title: t("任务流管理"),
      icon: highlight_17,
      backText: t(
        "领先的多复合工作流管理系统，支持标注、检查、抽查、验收、驳回、分发逻辑、高级流程逻辑、callback等功能。"
      ),
    },
    {
      title: t("SaaS自动化"),
      icon: highlight_16,
      backText: t(
        "SaaS自动化，支持整个标注、质检、上岗、系统对接的自动化，通过事件触发脚本，极大提高效率。"
      ),
    },
    {
      title: t("数据安全"),
      icon: highlight_15,
      backText: t(
        "通过水印、不可复制、黑白名单、数据权限、访问控制、数据加密等方式保证数据安全。"
      ),
    },
    {
      title: t("数据处理"),
      icon: highlight_13,
      backText: t(
        "支持预处理脚本、入库脚本、回调函数、数据检验、数据分配和数据流转通知。"
      ),
    },
    {
      title: t("结构化数据管理"),
      icon: highlight_12,
      backText: t("标注结果可以映射入库、支持结构化查询检索、支持数据可视化。"),
    },
    {
      title: t("数据管理"),
      icon: highlight_14,
      backText: t(
        "支持预处理脚本、入库脚本、回调函数、数据检验、数据分配和数据流转通知。"
      ),
    },
  ];

  const groupList: GroupCardProps[] = [
    {
      title: t("全"),
      rightIcon: group_01,
      list: [
        t("覆盖点云、图像、文本、语音、采集等各类项目；"),
        t("支持 100+ 种主流标注场景；"),
        t("数百个标注功能，满足极端标注细节"),
      ],
    },
    {
      title: t("多"),
      rightIcon: group_02,
      list: [
        t("支持创建上万人的团队"),
        t("支持全球上万人同时工作"),
        t("一个项目支持同时标注上百万道题目"),
        t("一道题目支持标记上万个标签且不卡顿"),
      ],
    },
    {
      title: t("快"),
      rightIcon: group_03,
      list: [
        t("2 分钟快速建项目并开始标注"),
        t(
          "提供算法预标注，包括OCR、ASR、语义分割、车辆MOT等算法，准确率可达 80% 以上，提升至少 2-5 倍标注效率"
        ),
        t("标注员可实时获取算法、规则质检反馈，从而及时修改标注错误"),
        t("每小时可生产 100w+ 个合格标签"),
        t("只需要几行代码即可导入、导出数据，并用于算法训练"),
      ],
    },
    {
      title: t("细"),
      rightIcon: group_04,
      list: [
        t(
          "标签级别的质检颗粒度，支持标记错误理由和错误类型，并能查看项目常见错误"
        ),
        t("标签级别的进度、结算统计颗粒度，并每天自动发送统计信息到邮箱"),
      ],
    },
    {
      title: t("准"),
      rightIcon: group_05,
      list: [
        t(
          "提供至少 6 种质检方法，涵盖算法质检、规则质检、全样质检、抽样质检、多层质检等方式，并可对主观题进行交叉质检"
        ),
        t("图片、文本类项目标签颗粒度的准确率可达 99.9%"),
        t("点云、视频类项目标签颗粒度的准确率可达 99.5%"),
      ],
    },
    {
      title: t("灵活"),
      rightIcon: group_06,
      list: [
        t("项目过程中可随时增、删、修改标签，便于打磨标注规则"),
        t(
          "业内独有 DAG 工作流程管理方法，在一个项目中可同时、灵活地管理数十个团队、数万人的工作"
        ),
        t(
          "业内独创的父子嵌套标签关系定义方法，灵活配置项目所需标签，进一步打磨出最具效率的标签配置方式"
        ),
      ],
    },
  ];

  return (
    <SkewMainContainer fillClassName="light-white-bg">
      <SectionTitle id="productHighlight" className="mb-8">
        {t("产品亮点")}
      </SectionTitle>
      <SectionDescribe className="mb-8">
        {t(
          "标注平台集标注工具、流程管控、质量控制及人员管理为一体，对数据安全和算法接入提供全方位支持。"
        )}
      </SectionDescribe>
      <GroupCard list={groupList.slice(0, 2)} colsClassName="sm:grid-cols-2" />
      <HighlightCard lists={lists.slice(0, 8)} />
      <GroupCard list={groupList.slice(2, 3)} colsClassName="sm:grid-cols-1" />
      <HighlightCard lists={lists.slice(8, 16)} />
      <GroupCard list={groupList.slice(3, 5)} colsClassName="sm:grid-cols-2" />
      <HighlightCard lists={lists.slice(16, 24)} />
      <GroupCard list={groupList.slice(5, 6)} colsClassName="sm:grid-cols-1" />
      {/*<HighlightCard lists={lists.slice(24, 28)} />*/}
    </SkewMainContainer>
  );
};

const HighlightCard = memo((props: { lists: HighlightCardProps[] }) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 mb-12">
      {props.lists.map((list, index) => (
        <FlipCard
          front={
            <div className="flex flex-col justify-center items-center bg-white w-full h-full">
              <img className="w-12" src={list.icon} alt="" />
              <p className="mt-4 text-black text-center">{list.title}</p>
            </div>
          }
          back={
            <div className="flex flex-col justify-start items-center px-4 text-white text-xs leading-5 text-center h-full bg-gradient-to-r from-blue-600 to-purple-600">
              <p className="text-base mt-8 ">{list.title}</p>
              <p className="mt-4">{list.backText}</p>
            </div>
          }
          key={list.title}
          className="rounded-md cursor-pointer h-64 w-full transition hover:scale-105"
        />
      ))}
    </div>
  );
});

const GroupCard = memo(
  (props: { list: GroupCardProps[]; colsClassName: string }) => {
    return (
      <div
        className={classNames(
          "grid grid-cols-1 gap-8 mb-12",
          props.colsClassName
        )}
      >
        {props.list.map((group, index) => (
          <div
            key={index}
            className="relative rounded-lg p-10 bg-white overflow-hidden z-10 news-card"
          >
            <img
              className="absolute right-0 top-0 z-[-1]"
              src={group.rightIcon}
              alt=""
            />
            <h3 className="text-5xl mb-8">{group.title}</h3>
            {group.list.map((item) => (
              <div className="flex items-start mb-1">
                <div className="w-1 h-1 rounded-full bg-black mr-2 mt-2 flex-none" />
                <p className="text-[#666] leading-6 text-base max-w-[640px]">
                  {item}
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
);

export default ProductHighlights;
