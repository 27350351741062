import classNames from "classnames";
import * as React from "react";
import { memo, ReactNode, useEffect } from "react";
import "./termynal.css";
// @ts-ignore
import Termynal from "./Termynal.js";

export const HaveCompleteAPICode = memo(() => {
  return (
    <>
      <TermynalWrap progressLength={28.5} className="sm:block hidden" id="pc">
        <CodeContent />
      </TermynalWrap>
      <TermynalWrap progressLength={10} className="sm:hidden" id="mobile">
        <CodeContent />
      </TermynalWrap>
    </>
  );
});

const TermynalWrap = memo(
  (props: {
    className?: string;
    children?: ReactNode;
    progressLength: number;
    id: string;
  }) => {
    useEffect(() => {
      new Termynal(`#${props.id}`, { progressLength: props.progressLength });
    }, []);
    return (
      <div
        id={props.id}
        data-termynal
        data-ty-typeDelay="40"
        data-ty-lineDelay="700"
        className={classNames("sm:h-[365px] h-[648px] w-full", props.className)}
      >
        {props.children}
      </div>
    );
  }
);

const CodeContent = memo(() => {
  return (
    <>
      <span data-ty="input">npm install -g @stardustai/cli</span>
      <span data-ty="progress" />
      <span data-ty>Successfully installed stardustai</span>
      <span data-ty />
      <span data-ty="input">stardustai export:project</span>
      <span data-ty="progress" />
      <span data-ty>Project export completed</span>
    </>
  );
});
