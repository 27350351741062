import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { memo, useState } from "react";
import EnlargerImage from "react-image-enlarger";
import { SkewMainContainer } from "../../components/Container";
import { SearchIcon } from "../../components/Icon";
import { SectionTitle } from "../../components/Title";
import { DataCheck } from "../../images";
import { plan_01, plan_01_en, plan_02, plan_02_en } from "../../images/product";
import { useLanguage } from "../../utils/utils";

const DataSecurityView = memo(() => {
  const [zoomedPlanOne, setZoomedPlanOne] = useState(false);
  const [zoomedPlanTwo, setZoomedPlanTwo] = useState(false);
  const { t } = useTranslation();
  const { isEnUs } = useLanguage();
  const planOneLists = [
    t(
      "甲方部署文件服务器， 采用tls加密（https）可以保证数据在传输过程中不被窃听。"
    ),
    t(
      "甲方设置文件服务器的访问频率限制，同时记录所有用户的访问地址和时间，设立高频预警和禁止IP访问的规则可以防止用户窃取大批量数据。"
    ),
    t(
      "甲方设立VPN服务器， 标注人员登陆VPN服务器访问内网资源。 可以只允许标注员用户访问，控制访问的流量和内容。"
    ),
    t("甲方设立白名单，只允许固定IP访问"),
    t("浏览器Canvas加载，无法右键下载"),
  ];

  const planTwoTitleLists = [
    t("端到端数据加密，包括数据库加密存储、数据传输加密、数据使用加密"),
    t("数据存储在CPU的特殊内核中，防止内存攻击等黑客攻击"),
    t(
      "得到和离线部署一样的安全性，但开发复杂度低，合作流程短，几天就可以完成定制化"
    ),
  ];
  const planTwoLists = [
    <div>
      {t(
        "通过非对称加密方式，客户对数据进行私钥加密后，存放在自己的数据库里面，对外提供http访问加密后的数据。因为没有公钥，"
      )}
      <span className="primary-text">{t("即使数据泄露也无法解密数据。")}</span>
    </div>,
    <div>
      {t("通过SSL传输加密后的数据，即使数据被截取，也")}
      <span className="primary-text">{t("无法解密，确保原始不能泄露。")}</span>
    </div>,
    <div>
      {t("星尘为每一个客户定制一套桌面应用程序，只有")}
      <span className="primary-text">
        {t("正确的桌面端才能解密客户数据。")}
      </span>
    </div>,
    <div>
      {t("标注员只能通过")}
      <span className="primary-text">
        {t("定制加密的客户端进行标注，但无法获取数据本身")}
      </span>
      {t(
        "。数据通过内核渲染到屏幕上可以被查看，通过嵌入式应用CPU的加密存储。因此无法通过截取的方式得到数据。和离线安全一样安全。"
      )}
    </div>,
  ];

  return (
    <SkewMainContainer fillClassName="light-white-bg">
      <SectionTitle id="dataSecurity" className="mb-8 text-xl">
        {t("数据安全")}
      </SectionTitle>
      <div className="grid grid-cols-1 sm:grid-cols-8 gap-12">
        <div className="sm:col-span-3">
          <h3 className="text-size-24">{t("方案1：离线数据安全访问方案")}</h3>
          <p className="text-color-blue-gary text-size-14 mb-8 mt-6">
            {t(
              "星尘数据安全方案能够极大的提高数据因为黑客攻击导致泄露的风险。这套方案特点是甲方数据不在星尘存储留档，而是存储在甲方的离线服务器中。在项目进行时，通过提交认证，甲方作业员通过浏览器从甲方服务器下载数据至浏览器缓存，作业结束后即自行销毁。一但作业结束以后，数据便无法访问，极大的提高了数据的安全性。"
            )}
          </p>
          {planOneLists.map((list) => (
            <div key={list} className="flex items-start mb-2">
              <DataCheck className="flex-none mr-2" />
              <p className="text-color-blue-gary text-xs leading-6">{list}</p>
            </div>
          ))}
        </div>
        <div className="sm:col-span-5">
          <div className="bg-white shadow-offset-30 pt-9 px-5 pb-6 rounded">
            <EnlargerImage
              zoomed={zoomedPlanOne}
              src={isEnUs ? plan_01_en : plan_01}
              onClick={() => setZoomedPlanOne(true)}
              onRequestClose={() => setZoomedPlanOne(false)}
              overlayColor="#00000030"
            />
            <EnlargeButton onClick={() => setZoomedPlanOne(true)} />
          </div>
        </div>
        <div className="sm:col-span-5 shadow-offset-30 bg-white pt-9 px-5 pb-4 rounded flex justify-center flex-col">
          <EnlargerImage
            zoomed={zoomedPlanTwo}
            src={isEnUs ? plan_02_en : plan_02}
            onClick={() => setZoomedPlanTwo(true)}
            onRequestClose={() => setZoomedPlanTwo(false)}
            overlayColor="#00000030"
          />
          <EnlargeButton onClick={() => setZoomedPlanTwo(true)} />
        </div>
        <div className="sm:col-span-3">
          <h3 className="text-size-24 mb-6">{t("方案2：端到端加密")}</h3>
          {planTwoTitleLists.map((item, index) => (
            <div key={item} className="flex mb-2">
              <span>{index + 1}.</span>
              <p className="text-color-blue-gary text-size-14">{item}</p>
            </div>
          ))}
          <div className="mt-10">
            {planTwoLists.map((list, index) => (
              <div key={index} className="flex items-start mb-4">
                <div className="flex-none mr-2 w-[18px] h-[18px] rounded-full product-list-number primary-text flex justify-center items-center mt-[2px]">
                  <span className="text-xs">{index + 1}</span>
                </div>
                <div className="text-color-blue-gary text-xs">{list}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div
          className="flex bg-white rounded-full border h-10 items-center mt-24 w-[378px] justify-center cursor-pointer"
          onClick={() => navigate("/download")}
        >
          <p className="primary-text">
            {t("更多信息，请下载《星尘数据安全白皮书》")}
          </p>
        </div>
      </div>
    </SkewMainContainer>
  );
});

const EnlargeButton = memo((props: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <div
      className="justify-center items-center mt-12 hidden sm:flex cursor-pointer"
      onClick={props.onClick}
    >
      <SearchIcon
        className="cursor-pointer"
        strokeOpacity={0.2}
        strokeWidth={2}
        stroke="#000"
      />
      <p className="primary-text ml-2">{t("点击放大")}</p>
    </div>
  );
});
export default DataSecurityView;
