import * as React from "react";
import { memo, ReactNode } from "react";
import "./flipCard.css";
const FlipCard = memo(
  (props: { front: ReactNode; back: ReactNode; className: string }) => {
    return (
      <div className="flip-card-container h-[240px]">
        <div className="front h-full w-full">{props.front}</div>
        <div className="back h-full w-full">{props.back}</div>
      </div>
    );
  }
);

export default FlipCard;
